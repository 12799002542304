<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                    <span style="color: white">
                        {{ msg }}
                    </span>
                </v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-app id="inspire">
            <v-img height="100vh" src="../../assets/Images/ECOFRAME.png">
                <v-layout wrap justify-center fill-height>
                    <v-flex xs12 align-self-center>
                        <v-layout wrap justify-end>
                            <v-flex xs7>
                                <v-layout wrap justify-center>
                                    <v-flex xs7>
                                        <v-card>
                                            <v-layout wrap justify-center>
                                                <v-flex xs3 pt-5>
                                                    <v-img height="60px" contain
                                                        src="../../assets/Images/blacklogo.png"></v-img>
                                                </v-flex>
                                                <v-flex xs10 text-center pt-2 class="mainfont">
                                                    <span style="font-size: 22px;">Register</span>
                                                </v-flex>
                                                <v-flex xs10 pt-6>
                                                    <!-- <v-text-field color="#717171" label="Name" outlined dense
                                                        style="font-family: mainfont" v-model="officeName" hide-details>
                                                    </v-text-field> -->
                                                </v-flex>
                                                <v-flex xs10 pt-6>
                                                    <v-text-field color="#717171" label=" Name" outlined dense
                                                        style="font-family: mainfont" v-model="username"
                                                        hide-details></v-text-field>
                                                </v-flex>
                                                <v-flex xs10 pt-6>
                                                    <v-text-field :rules="emailIdRules" color="#717171" label="E-mail"
                                                        outlined dense style="font-family: mainfont" v-model="emailId"
                                                        hide-details>
                                                    </v-text-field>
                                                </v-flex>
                                                <v-flex xs10 pt-6>
                                                    <v-text-field color="black" outlined background-color="white" dense
                                                        label=" Phone  Number" type="number" :rules="guestPhoneRules"
                                                        v-model="mobNumber" class="inputPrice" hide-details>
                                                    </v-text-field>
                                                </v-flex>
                                                <v-flex pt-6 xs10>
                                                    <v-text-field color="#717171" label="Password" outlined dense
                                                        style="font-family: mainfont" v-model="password"
                                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="showPassword ? 'text' : 'password'"
                                                        @click:append="showPassword = !showPassword" hide-details>
                                                    </v-text-field>
                                                </v-flex>
                                                <v-flex pt-6 xs10>
                                                    <v-text-field color="#717171" label="Confirm Password" outlined
                                                        dense style="font-family: mainfont" v-model="confirmpassword"
                                                        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="showPassword2 ? 'text' : 'password'"
                                                        @click:append="showPassword2 = !showPassword2" hide-details>
                                                    </v-text-field>
                                                    <v-layout wrap>
                                                        <v-flex xs12 pt-3 class="mainfont">
                                                            <v-alert v-if="!passwordsMatch" type="error">
                                                                Passwords do not match
                                                            </v-alert>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <!-- <v-flex xs10 pt-3>
  
                                                <v-autocomplete outlined dense color="black" :items="itemArray2"
                                                    label="Applicant Category" v-model="xsx" item-text="name"
                                                    item-value="name" item-color="#FF1313" hide-details clearable
                                                    class="custom-autocomplete  mainfont">
                                                    <template v-slot:append>
                                                        <v-icon color="black">mdi-chevron-down</v-icon>
                                                    </template>
</v-autocomplete>
</v-flex> -->
                                                <v-flex pt-6 xs10>
                                                    <v-btn class="no-uppercase mainfont" dark block color="rgba(112, 21, 169, 1)"
                                                        @click.prevent="validateInput"
                                                        :disabled="!arePasswordsMatching">
                                                        <span>
                                                            Submit
                                                            &nbsp;
                                                        </span>
                                                        <v-icon color="white">
                                                            mdi-arrow-right
                                                        </v-icon>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex @click="$router.push('/')" style="cursor: pointer;" pb-8
                                                    class="mainfont" text-center pt-3 xs10>
                                                    <span>Already a user? click here to Login</span>
                                                </v-flex>



                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-flex>


                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-img>
        </v-app>
    </div>
</template>

<script>
import axios from "axios";
// import store from "./../../store";
export default {
    data() {
        return {
            ServerError: false,
            itemArray2: ["Student", "Non-vegetarian"],
            showSnackBar: false,
            timeout: 5000,
            officeName: null,
            msg: null,
            emailId: null,
            mobNumber: null,
            otp: null,
            phone: null,
            showPassword: false,
            showPassword2: false,
            passwordsMatch: true,
            username: null,
            password: null,
            confirmpassword: null,
        };
    },
    mounted() {
        this.phone = this.$route.query.phone;
    },

    watch: {
        confirmpassword(newConfirmPassword) {
            if (newConfirmPassword !== this.password) {
                this.passwordsMatch = false;
            } else {
                this.passwordsMatch = true;
            }
        },
    },
    computed: {
        arePasswordsMatching() {
            return this.password === this.confirmpassword;
        },
        guestPhoneRules() {
            return [
                (v) => !!v || "Phone number is required",
                (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
            ];
        },
        emailIdRules() {
            return [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ];
        },


    },
    methods: {
        validateInput() {
            if (!this.username) {
                this.msg = "Please provide a username";
                this.showSnackBar = true;
                return;
            } else if (!this.emailId) {
                this.msg = "Please provide an email";
                this.showSnackBar = true;
                return;
            } else if (!this.isValidEmail(this.emailId)) {
                this.msg = "Please provide a valid email address";
                this.showSnackBar = true;
                return;
            } else if (!this.mobNumber) {
                this.msg = "Please provide an phone number";
                this.showSnackBar = true;
                return;
            } else if (!this.isValidMobileNumber(this.mobNumber)) {
                this.msg = "Please provide a valid mobile number";
                this.showSnackBar = true;
                return;
            } else if (!this.password) {
                this.msg = "Please provide a password";
                this.showSnackBar = true;
                return;
            } else if (!this.confirmpassword) {
                this.msg = "Please confirm the password";
                this.showSnackBar = true;
                return;
            } else {
                this.register();
            }
        },
        isValidEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        },


        isValidMobileNumber(mobNumber) {
            const mobileRegex = /^[0-9]{10}$/;
            return mobileRegex.test(mobNumber);
        },

        register() {
            axios({
                method: "POST",
                url: "/add/user",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    username: this.username,
                    officeName: this.username,
                    password: this.password,
                    mobNumber: this.mobNumber,
                    emailId: this.emailId,
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        this.msg = response.data.msg;
                        const userData = response.data.data;
                        const userId = userData._id;
                        this.showSnackBar = true;

                        setTimeout(() => {
                            this.$router.push({ path: "/verify", query: { userId } });
                        }, 3000);
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.error(err);
                });
        },
    },
};
</script>
<style>
.no-spinners {
    /* Remove the up and down arrows */
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>