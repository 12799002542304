<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
            spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 pt-8 text-left>
            <span style="font-size: 25px;font-weight: bolder;">APPLICATIONS </span>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable dense outlined readonly v-model="fromDate" v-bind="attrs" background-color="white"
                  v-on="on" @click:clear="from = null" style="color: black">
                  <template v-slot:append>
                    <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="custom-label-text" style="color: black; font-size: 14px">
                      From Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="fromDate" color="#13736f" @change="menu1 = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" max-width="290" elevation="0">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable label="To Date" readonly dense background-color="white" color="#13736f" outlined
                  v-model="toDate" v-bind="attrs" v-on="on" @click:clear="from = null">
                  <template v-slot:append>
                    <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="custom-label-text" style="color: black; font-size: 14px">
                      To Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="toDate" color="#13736f" @change="menu2 = false" :min="fromDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pr-1 sm3 xs12 align-self-center>
            <v-text-field v-model="keyword" outlined dense hide-details clearable color="orange" label="Search "
              class="custom-text-field" style="border-radius: 0%" placeholder="Application Number">
              <template v-slot:label>
                <span class="custom-label-text mainfont" style="color: black; font-size: 14px">Search
                </span>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <!-- <v-layout wrap justify-center>
          <v-flex xs12><span style="font-size: 18px; font-weight: bolder">TASK TO BE DONE:</span>&nbsp;<span
              style="color: red;">Enter remarks, forward application to DFO/send back
              application to Clerk for clarification.</span></v-flex>
        </v-layout> -->
        <v-layout  wrap align-center justify-center>
          <v-flex xs12>
            <div style="display: flex; align-items: center;">
              <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
              <span style="font-size: 17px; font-weight: bold;">TASK TO BE DONE :</span>&nbsp;<span
                style="color: red; font-size: 16px;">Enter remarks, forward application to DFO/send back
              application to Clerk for clarification.</span>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-4 v-for="(item, i) in list" :key="i">
        <v-layout wrap>
          <v-flex xs12>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs3 pt-3 pb-3 pl-3 v-if="item.paymentStatus === 'Success'">
                  <div style="position: relative;">
                    <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                      <div
                        style="position: absolute; top: 10px; left: 10px; padding: 5px; background-color: #228b22 ; color: white; font-weight: bold; border-radius: 5px;">
                        Payment Done</div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs3 pt-3 pb-3 pl-3 v-else>

                  <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>




                </v-flex>
                <v-flex xs9 pt-3>
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex pl-4 xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Number

                          </span>
                          <br />
                          <span>{{ item.applicationNo }}</span>
                        </v-flex>
                        <v-flex pl-4 xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Name

                          </span>
                          <br />
                          <span>{{ item.applicantName }}</span>
                        </v-flex>
                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Disrict Name

                          </span>
                          <br />
                          <span>{{ item.applicantDistrict }}</span>
                        </v-flex>
                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Mobile Number

                          </span>
                          <br />
                          <span>{{ item.applicantMobileNo }}</span>
                        </v-flex>
                        <v-flex xs3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Email

                          </span>
                          <br />
                          <span>{{ item.applicantEmail }}</span>
                        </v-flex>
                        <v-flex xs3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Security Deposit

                          </span>
                          <br />
                          <span>{{ item.securitydeposit }}</span>
                        </v-flex><v-flex xs3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Shooting Fees

                          </span>
                          <br />
                          <span>{{ item.shootingfee }}</span>
                        </v-flex><v-flex xs3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Refund Amount

                          </span>
                          <br />
                          <span>{{ item.refundAmount }}</span>
                        </v-flex>

                      </v-layout>
                    </v-flex>
                    <v-flex xs12 align-self-center>
                      <v-layout wrap justify-start>
                        <v-flex xs2 pb-4 text-center>
                          <v-btn @click="$router.push('/DetailedViewsuperintedent?id=' + item._id)" plain
                            class="text-capitalize" color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>


                        </v-flex>
                        <!-- <v-flex xs2 pb-4 text-center>
                          <v-btn @click="navigateToDetailedViewSuperintedent(item._id)" plain class="text-capitalize"
                            color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>
                        </v-flex> -->


                        <!-- <v-flex xs2 pb-4 text-center>
                            <v-btn plain class="text-capitalize" @click="forwardToUsers(item._id), deletedialog = true"
                              color="green">
                              <v-icon color="green">mdi-share</v-icon>&nbsp;
                              <span>FORWARD</span>
                            </v-btn>
                          </v-flex> -->

                      </v-layout>
                    </v-flex>

                  </v-layout>

                </v-flex>



              </v-layout>
            </v-card>
          </v-flex>


        </v-layout>


      </v-flex>
      <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="700px">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs8 text-left pt-5>
              <span class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want
                to forward?
              </span>
            </v-flex>
            <v-flex xs8 pt-6 pr-4 class="mainfont">
              <v-autocomplete outlined dense color="black" :items="usernames" v-model="selectedUsername" item-text="name"
                placeholder="Select a Role" style="font-family: mainfont;" item-value="name" item-color="#FF1313"
                hide-details clearable class="custom-autocomplete">
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs8 pt-5 pr-4 class="mainfont">
              <v-text-field placeholder="Reason" v-model="reason" dense class="mainfont" outlined color="black">
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="forwardToUsers2(appids)">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout wrap>
      <v-flex text-center xs12 v-if="list.length < 1">
        <span>No Applications !</span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 v-if="list.length > 0">
        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      curIdToDelete: null,
      name: null,
      reason: null,
      editdialog: false,
      barlist: [],
      edit: [],
      userProfileTabs: null,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],
      pages: 0,
      usernames: [],
      selectedUsername: null,
      dialog1: false,
      preview: null,
      tab: null,
      barlist1: [],
      gender: ["Male", "Female", "Transgender"],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: null,
      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      currentPage: 1,
      showSnackBar: false,
      users: [],
      username1: null,
      username2: null,
      keyword: "",
      dialog2: false,
      viewdialog: false,
    };
  },

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
    keyword() {
      this.getList();
    },
  },
  computed: {

    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },


  methods: {
  //   navigateToDetailedViewSuperintedent(id) {

  //   const encryptedId = CryptoJS.AES.encrypt(id.toString(), 'encryptionSecret').toString();
  //   this.$router.push({
  //     path: '/DetailedViewsuperintedent',
  //     query: {
  //       id: encryptedId
  //     }
  //   });
  // },
    forwardToUsers(_id) {
      this.getUsers(_id);
      this.appids = _id;
    },

    forwardToUsers2(appids) {
      this.forward(appids);
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },

    getUsers() {
      axios({
        method: "post",
        url: "/superintendent/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
        },
      })
        .then((response) => {
          this.usernames = [
            { name: `${response.data.clerk.username} (clerk)`, role: "clerk", userId: response.data.clerk._id },
            { name: `${response.data.dfo.username} (dfo)`, role: "dfo", userId: response.data.dfo._id },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    forward(appids) {
      if (!this.selectedUsername) {
        this.$refs.snackbar.showSnackbar("Please select a user to forward to.");
        return;
      }

      const selectedUser = this.usernames.find(user => user.name === this.selectedUsername);
      if (!selectedUser) {
        return;
      }

      axios({
        method: "post",
        url: "/forward/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appids,
          forwardto: selectedUser.userId,
          reason: this.reason,
        }
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    getList() {
      this.appLoading = true;
      var formattedFromDate = new Date(this.fromDate).toISOString();
      if (this.toDate != null) {
        var formattedToDate = new Date(this.toDate).toISOString();
      }
      axios({
        method: "POST",
        url: "/forwarded/apps",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          task: "T5",
          status: "yes",
          categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: formattedFromDate,
          toDate: formattedToDate,
          applicationNo: this.keyword,
          limit: 10,
        },
      })
        .then((response) => {
          this.list = response.data.data.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
          });
          this.totalData = response.data.totalLength;
          this.selectedDatesArray = this.list.map((item) => item.selectedDate);
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

  },
};
</script>
        
<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;

}

.heading {
  font-family: mainfont;
  color: rgb(229, 111, 8);
  text-transform: none;
  font-size: 25px;

}
</style>