<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
        spinner="spinner" />
      <v-flex>
        <v-layout wrap>
          <v-flex xs11 text-center pt-7 pb-2>
            <span style="font-size: 23px; font-weight: bold">Application form for film Shooting</span>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap justify-center pa-4>
                <v-flex xs11 lg3 pa-2>
                  <v-form @submit.prevent="validateInput">
                    <v-autocomplete clearable dense outlined label="Circle" color="black" :items="circle"
                      v-model="circleid" item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                      class="custom-autocomplete mainfont">
                      <template v-slot:label>
                        <span class="custom-label-text" style="color: black; font-size: 14px">
                          Circle
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-form>
                </v-flex>

                <v-flex xs11 lg3 pa-2>
                  <v-form @submit.prevent="validateInput">
                    <v-autocomplete clearable dense outlined label="Division" color="black" :items="division"
                      v-model="divisionid" item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                      class="custom-autocomplete mainfont" :disabled="!circleid">
                      <template v-slot:label>
                        <span class="custom-label-text" style="color: black; font-size: 14px">
                          Division
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-form>
                </v-flex>

                <v-flex xs11 lg3 pa-2>
                  <v-form @submit.prevent="validateInput">
                    <v-autocomplete clearable dense outlined label="Range" color="black" :items="range" v-model="rangeid"
                      item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                      class="custom-autocomplete mainfont" :disabled="!divisionid">
                      <template v-slot:label>
                        <span class="custom-label-text" style="color: black; font-size: 14px">
                          Range
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-form>
                </v-flex>

                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-text-field label="Mobile Number" type="text" dense color="#283e51" outlined background-color="white"
                    v-model="applicantName" :rules="applicantnameRules" hide-details="auto" >
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Name of the Applicant
                        <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>

                <!-- <v-flex  xs11 lg3 pa-2 text-left align-self-center>
                  <v-text-field label="Mobile Number" type="number" :rules="PhoneRules" dense color="#283e51" outlined
                    background-color="white" v-model="applicantMobileNo" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Mobile Number <span style="color: rgb(255, 0, 0);">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex> -->
                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-text-field label="Mobile Number" type="number" :rules="PhoneRules" dense color="#283e51" outlined
                    background-color="white" v-model="applicantMobileNo" hide-details="auto" class="inputPrice">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Mobile Number
                        <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-text-field label="Email" :rules="emailRules" dense color="#283e51" outlined background-color="white"
                    v-model="applicantEmail" hide-details="auto" >
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Email <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>

                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-text-field label="Address" dense color="#283e51" outlined background-color="white"
                    v-model="applicantAddress" :rules="addressRules" hide-details="auto" >
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Address <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-autocomplete outlined dense color="black" :items="districts" v-model="applicantDistrict"
                    label="District" item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                    class="custom-autocomplete mainfont">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">District
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-text-field label="Pincode" type="number" dense color="#283e51" outlined class="inputPrice"
                    background-color="white" v-model="applicantPinCode" :rules="pincodeRules" hide-details="auto" >
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Pincode <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <!-- <v-flex  xs11 lg3 pa-2  text-left align-self-center>
                  <v-autocomplete label="Nature of Application"  dense color="#283e51" outlined
                    background-color="white" :items="natureOfApplication" v-model="natureOfApplication" hide-details clearable
                    class="custom-autocomplete mainfont">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Nature of Application <span style="color: rgb(255, 0, 0);">*</span>
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex> -->
                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-autocomplete outlined dense color="black" :items="natureOfApplicationitem"
                    v-model="natureOfApplication" label="Nature Of Application" item-text="name" item-value="name"
                    item-color="#FF1313" hide-details clearable class="custom-autocomplete mainfont">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Nature Of Application<span
                          style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center>
                  <v-autocomplete outlined dense color="black" :items="idcardtypes" v-model="idType" label="Id Card Type"
                    item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                    class="custom-autocomplete mainfont">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Id card type
                        <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center v-if="idType === 'Driving License'">
                  <v-text-field label="Id Card Number" dense color="#283e51" outlined background-color="white"
                    v-model="idcardNo" :rules="DrivingLicenseRules" hide-details="auto">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Id Card Number
                        <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center v-if="idType === 'Election Id Card'">
                  <v-text-field label="Id Card Number" dense color="#283e51" outlined background-color="white"
                    v-model="idcardNo" :rules="ElectionIdCardRules" hide-details="auto">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Id Card Number
                        <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs11 lg3 pa-2 text-left align-self-center v-if="idType === 'Aadhaar Card'">
                  <v-text-field label="Id Card Number" dense color="#283e51" outlined background-color="white"
                    v-model="idcardNo" :rules="AadhaarCardRules" hide-details="auto" >
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Id Card Number
                        <span style="color: rgb(255, 0, 0)">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <template>
                    <div>
                      <v-layout wrap>
                        <v-flex pl-4 pt-2 pb-2 text-left class="bgcustom">
                          <v-layout wrap justify-start>
                            <v-flex xs11 lg5 pt-2 align-self-center>
                              <span style="font-size: 20px; color: white">
                                Add Details of film shooting </span>&nbsp;
                              <v-btn small color="white" @click="filmShootingDetails()">
                                <v-icon color="black">mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start style="background-color: white">
                        <v-card flat>
                          <v-layout wrap>
                            <v-flex pt-1 v-for="(item, i) in leaderDetails" :key="i">
                              <v-layout wrap justify-start>
                                <v-flex xs12 lg3 pa-3>
                                  <v-text-field color="black" v-model="item.location" :rules="locationRules" dense outlined label="Name"
                                    hide-details="auto" >
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Location
                                        <span style="color: rgb(255, 0, 0)">*</span></span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs12 lg3 pa-3 v-if="natureOfApplication === 'Commercial Film' || natureOfApplication === 'Documentary' || natureOfApplication === 'Educational Film'">
                                  <v-text-field color="black" v-model="item.filmName" :rules="filenameRules" dense outlined label="Film Name"
                                    hide-details="auto" >
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Film Name
                                        <span style="color: rgb(255, 0, 0)">*</span></span>
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex xs12 lg3 pa-3>
                                  <v-menu ref="menu1" v-model="item.startDate" :close-on-content-click="false"
                                    max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field hide-details="true" clearable dense outlined readonly
                                        v-model="item.fromDate" v-bind="attrs" background-color="white" v-on="on"
                                        @click:clear="item.fromDate = null" style="color: black">
                                        <template v-slot:append>
                                          <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                                        </template>
                                        <template v-slot:label>
                                          <span class="custom-label-text" style="
                                              color: black;
                                              font-size: 14px;
                                            ">
                                            From Date
                                            <span style="color: rgb(255, 0, 0)">*</span>
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.fromDate" color="#13736f"
                                      @change="item.startDate = false" :min="minDate"></v-date-picker>
                                  </v-menu>
                                </v-flex>

                                <v-flex xs12 lg3 pa-3>
                                  <v-menu ref="menu2" v-model="item.endDate" :close-on-content-click="false"
                                    max-width="290" elevation="0">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field hide-details="true" clearable label="To Date" readonly dense
                                        background-color="white" color="#13736f" outlined v-model="item.toDate"
                                        v-bind="attrs" v-on="on" @click:clear="item.toDate = null">
                                        <template v-slot:append>
                                          <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                                        </template>
                                        <template v-slot:label>
                                          <span class="custom-label-text" style="
                                              color: black;
                                              font-size: 14px;
                                            ">
                                            To Date
                                            <span style="color: rgb(255, 0, 0)">*</span>
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.toDate" color="#13736f" @change="item.endDate = false"
                                      :min="item.fromDate"></v-date-picker>
                                  </v-menu>
                                </v-flex>

                                <v-flex xs12 lg3 pa-3>
                                  <v-text-field color="black" type="string" hide-spin-buttons v-model="item.noOfMembers"
                                    dense outlined label="Email" :rules="noofmembersRules"  hide-details="auto">
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        No. of Crew Members
                                        <span style="color: rgb(255, 0, 0)">*</span>
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs12 lg3 pa-3>
                                  <v-text-field color="black" type="string" v-model="item.noOfVehicles" dense outlined
                                    label="Email" :rules="noofvehicleRules" hide-details="auto" >
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        No. of Vehicle
                                        <span style="color: rgb(255, 0, 0)">*</span>
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex lg2 pt-3 pb-2>
                                  <center>
                                    <v-btn dark style="background-color: #68d389" dense @click="removeleaderdetails(i)">
                                      Remove
                                    </v-btn>
                                  </center>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-layout>
                    </div>
                  </template>
                </v-card>
              </v-flex>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <template>
                              <div>
                                <v-layout wrap justify-center>
                                  <v-flex pl-4 pt-2 pb-2 xs12 text-left class="bgcustom">
                                    <span style="font-size: 20px; color: white">
                                      Animal Details
                                    </span>
                                  </v-flex>
                                  <v-flex xs11 pb-6 pt-6>
                                    <v-layout wrap justify-start>
                                      <v-flex xs11 lg12 pa-2>
                                        <v-autocomplete clearable dense outlined color="black" :items="animal"
                                          v-model="animalinvolved" item-color="#FF1313" hide-details
                                          class="custom-autocomplete mainfont">
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              Whether any animal is involved in film shooting?
                                            </span>
                                          </template>
                                          <template v-slot:append>
                                            <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                                          </template>
                                        </v-autocomplete>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout wrap justify-start v-if="animalinvolved === 'YES'">
                                      <v-flex xs12 lg3 pa-3>
                                        <v-text-field color="black" v-model="species" :rules="speciesnameRules" dense outlined label="Name"
                                          hide-details="auto" >
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              Species Name
                                              <span style="color: rgb(255, 0, 0)">*</span></span>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-text-field color="black" type="number" hide-spin-buttons v-model="noOfAnimal"
                                          dense outlined label="Email" :rules="noofanimalsRules" hide-details="auto" class="inputPrice">
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              No. of Animals
                                              <span style="color: rgb(255, 0, 0)">*</span>
                                            </span>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-text-field color="black" type="string" hide-spin-buttons
                                          v-model="preshootNumber" :rules="preshootRules" dense outlined label="Email" hide-details="auto">
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              Preshoot Order Number
                                              <span style="color: rgb(255, 0, 0)">*</span>
                                            </span>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-menu ref="menu3" v-model="validitystartDate" :close-on-content-click="false"
                                          max-width="290">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field hide-details="true" clearable dense outlined readonly
                                              v-model="validityfromDate" v-bind="attrs" background-color="white" v-on="on"
                                              @click:clear="validityfromDate = null" style="color: black">
                                              <template v-slot:append>
                                                <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                                              </template>
                                              <template v-slot:label>
                                                <span class="custom-label-text" style="
                                              color: black;
                                              font-size: 14px;
                                            ">
                                                  Validity From Date
                                                  <span style="color: rgb(255, 0, 0)">*</span>
                                                </span>
                                              </template>
                                            </v-text-field>
                                          </template>
                                          <v-date-picker v-model="validityfromDate" color="#13736f"
                                             :min="minDatee"></v-date-picker>
                                        </v-menu>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-menu ref="menu4" v-model="validityendDate" :close-on-content-click="false"
                                          max-width="290">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field hide-details="true" clearable dense outlined readonly
                                              v-model="validitytoDate" v-bind="attrs" background-color="white" v-on="on"
                                              @click:clear="validitytoDate = null" style="color: black">
                                              <template v-slot:append>
                                                <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                                              </template>
                                              <template v-slot:label>
                                                <span class="custom-label-text" style="
                                              color: black;
                                              font-size: 14px;
                                            ">
                                                  Validity To Date
                                                  <span style="color: rgb(255, 0, 0)">*</span>
                                                </span>
                                              </template>
                                            </v-text-field>
                                          </template>
                                          <v-date-picker v-model="validitytoDate" color="#13736f"
                                             :min="validityfromDate"></v-date-picker>
                                        </v-menu>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout wrap justify-center v-if="animalinvolved === 'YES'">
                                      <v-flex xs11 lg6 text-center>
                                        <label for="Preshootfile">
                                          <span>Preshoot Order Upload
                                            <span style="color: rgb(255, 0, 0)">*</span>
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs11 lg6 pt-1>
                                        <input type="file" id="Preshootfile" ref="Preshootfile" accept=".pdf, .jpeg, .png"
                                          @change="handleFileChange2('Preshootfile')" />
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <template>
                              <div>
                                <v-layout wrap justify-center>
                                  <v-flex pl-4 pt-2 pb-2 xs12 text-left class="bgcustom">
                                    <span style="font-size: 20px; color: white">
                                      Scanned Documents
                                    </span>
                                  </v-flex>

                                  <v-flex xs11 pb-6 pt-6>
                                    <v-layout wrap justify-center>
                                      <v-flex xs11 lg6 text-center>
                                        <label for="idProof">
                                          <span>ID proof
                                            <span style="color: rgb(255, 0, 0)">*</span>
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs11 lg6 pt-1>
                                        <input type="file" id="idProof" ref="idProof" accept=".pdf, .jpeg, .png"
                                          @change="handleFileChange('idProof')" />
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap justify-center>
                    <v-flex pl-4 pt-2 pb-2 xs12 text-left class="bgcustom">
                      <span style="font-size: 20px; color: white">
                        Undertaking
                      </span>
                    </v-flex>
                    <v-flex pt-3 xs1>
                      <v-checkbox v-model="isAgree"></v-checkbox>
                    </v-flex>
                    <v-flex xs10 pb-5 text-left>
                      <v-layout wrap pt-4>
                        <v-flex xs12 pt-4 v-for="(content, index) in agreementContent" :key="index">
                          <span>{{ content }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pb-10>
                <v-layout wrap justify-end>
                  <v-flex xs11 lg2 pr-4>
                    <v-btn @click="validateInput()" color="black" block type="submit"
                      :disabled="!isAgree || status23 == true">
                      <span style="color: white">SUBMIT</span>
                    </v-btn>
                  </v-flex>
                  <!-- <v-flex xs2 pr-4>
                    <v-btn @click="(submitdialog = true)" color="black" block type="submit"
                      :disabled="checkvar == false ? true : false">
                      <span style="color:white">SUBMIT</span>
                    </v-btn>
                  </v-flex> -->
                </v-layout>
                <v-dialog :retain-focus="true" persistent v-model="submitdialog" max-width="800px">
                  <v-card>
                    <v-card-title class="mainfont" style="
                        color: black;
                        font-size: 18px;
                        font-weight: lighter;
                      ">
                      Are you sure you want to submit? Once submitted, you won't
                      be able to edit.</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="black" text @click="submitdialog = false" class="mainfont">Cancel</v-btn>
                      <v-btn color="green" class="mainfont" text @click="confirmSubmit()">Yes</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog :retain-focus="true" persistent v-model="successdialog" max-width="550px">
                  <v-card>
                    <v-layout wrap pa-2 justify-center text-center>
                      <v-flex xs12 pa-3>
                        <v-icon color="green" size="600%">mdi-check-circle-outline</v-icon>
                      </v-flex>
                      <v-flex xs12 pa-3 text-center>
                        <span class="mainfont" style="color: green; font-size: 30px">
                          Your submission has been sent
                        </span>
                      </v-flex>
                      <v-flex xs3 pb-2 text-center>
                        <v-btn @click="goBack()" color="green">
                          <span class="mainfont" style="color: white">
                            OK
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-dialog>
                <v-dialog :retain-focus="true" persistent v-model="memberdialog" max-width="350px">
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex xs10 pt-4 text-center>
                        <span class="mainfont" style="
                            color: black;
                            font-size: 18px;
                            font-weight: lighter;
                          ">
                          Only {{ availableSlots2 }} slots available
                        </span>
                      </v-flex>
                      <v-flex text-center>
                        <v-btn color="green" class="mainfont" text @click="submit2()">OK</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      animalinvolved: "",
      noOfAnimal: "",
      preshootNumber: "",
      species: "",
      validityfromDate: "",
      validitytoDate: "",
      idProof: null,
      reqLetter: null,
      applicantPinCode: "",
      participantfile: null,
      applicantAddress: null,
      idcardNo: null,
      applicantMobileNo: "",
      status23: false,
      Preshootfile:"",
      // minDate: new Date().toISOString().substr(0, 10),
      minDate: new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .substr(0, 10), 
      minDatee: null,
      leaderIdProof: null,
      gender: ["Male", "Female", "Transgender"],
      memberDetails: [],
      leaderName: null,
      availableSlots2: "",
      memberGender: ["Male", "Female", "Transgender"],
      leaderAddress1: null,
      leaderAddress2: null,
      telephone: null,
      idType: "Aadhaar Card",
      natureOfApplication: null,
      submitdialog: false,
      leaderPhone: null,
      leaderGender: null,
      leaderEmail: null,
      district: null,
      instHeadName: null,
      headDesignation: null,
      idCardTypeidCardType: null,
      idNo: null,
      idcardtypes: ["Election Id Card", "Driving License", "Aadhaar Card"],
      natureOfApplicationitem: [
        "Commercial Film",
        "Documentary",
        "Commercial Photography",
        "Educational Film",
        "Photography for Non Commercial purpose",
        "Accredited Press/Media Photographers",
      ],
      instName: null,
      instCode: null,
      instType: null,
      instAddress: null,
      instTeleNo: null,
      applicantName: null,
      applicantEmail: null,
      institutionTypes: ["Government", "Aided", "Private", "Others"],
      startDate: today,
      validitystartDate: null,
      validityendDate: null,
      startDatee: "",
      menu2: false,
      showSnackBar: false,
      isAgree: false,
      appid: {},
      appLoading: false,
      memberdialog: false,
      applicantDistrict: null,
      dist: this.$route.query.district,
      successdialog: false,
      msg: "",
      expandSection: false,
      name: null,
      barlist: [],
      agreementContent: [
        "I, hereby undertake on behalf of the Institution/Organization/Team that myself and all the Team members taking part in the Film Shooting shall abide by the conditions as per the existing laws pertaining to Forests & Wildlife, orders of the Forest Officers/competent authorities during the film shooting and maintain discipline throughout the course of the film shooting.",
      ],
      list: {},
      selectedDistrict: null,
      districts: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      campDuration: "",
      menu1: false,
      menu3: false,
      menu4: false,
      male: null,
      availableSlots: {},
      nameofOffice: null,
      message: null,
      checkvar: false,
      range: [],
      division: [],
      noOfMembers: null,
      noOfVehicles: null,
      accName: null,
      divisionid: null,
      circleid: null,
      toDate: null,
      rangeid: null,
      animal: ["YES",
        "NO"],
      circle: [],
      // leaderDetails: [],
      applicantCategory: "",
      applicantname: null,
      applicantcategorys: [
        "Students",
        "Press Reporters",
        "LSG Members",
        "NGO youth/charitable organization",
      ],
      location:'',
      leaderDetails: [
      {
        location: "",
        startDate: "",
        endDate: "",
        noOfMembers: "",
        noOfVehicles: "",
        filmName: "",
      },
    ],
    };
  },
  watch: {
    rangeid() {
      // this.getList();
    },

    circleid(newVal) {
      if (!newVal) {
        this.divisionid = null;
        this.rangeid = null;
      }
      this.getDivision();
      // this.getList();
    },

    divisionid(newVal) {
      if (!newVal) {
        this.rangeid = null;
      }
      this.getRange();
      // this.getList();
    },
    // startDate() {
    //   this.checkAvailability();
    // },
    isAgree() {
      if (this.isAgree == true) {
        this.checkvar = false;
      }
    },
    // nameofOffice() {
    //   this.checkAvailability2();
    // },
  },
  created() {
    this.dist = this.$route.query.district;
  },
  computed: {
    // minDate() {
    //   const today = new Date();
    //   const year = today.getFullYear();
    //   const month = today.getMonth() + 1;
    //   const day = today.getDate();
    //   return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    // },
    applicantnameRules() {
            return [
                (v) => !!v || "Applicant Name is required",
                (v) => /^[A-Za-z\s]+$/.test(v) || "Username must contain only alphabets and spaces"
            ];
            },
    PhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    addressRules() {
      return [
        (v) => !!v || "Address is required",
        (v) => /^[A-Za-z0-9\s]+$/.test(v) || "Address sholud not contain only special Charecters"
      ];
    },
    pincodeRules() {
      return [
        (v) => !!v || "Pincode is required",
        (v) => /^[0-9]{6}$/.test(v) || "Pincode must be 6 digits",
      ];
    },
    idcardRules(){
      return [
        (v) => !!v || "ID Card number is required",
      ];
    },

    DrivingLicenseRules() {
  return [
    (v) => !!v || "Driving License number is required",
    (v) => /^[A-Z0-9-]{16}$/.test(v) || "Driving License must be 16 alphanumeric characters",
  ];
},
    ElectionIdCardRules() {
    return [
      (v) => !!v || "Election ID number is required",
      (v) => /^[A-Z]{3}[0-9]{7}$/.test(v) || "Election ID must have 3 letters followed by 7 digits",
    ];
  },

  AadhaarCardRules() {
    return [
      (v) => !!v || "Aadhaar number is required",
      (v) => /^[0-9]{12}$/.test(v) || "Aadhaar number must be 12 digits",
    ];
  },

    locationRules() {
    return [
      (v) => !!v || "Location is required",
      (v) => /^[a-zA-Z\s]+$/.test(v) || "No Numbers or Special Charecters are Allowes"
    ];
  },
    filenameRules(){
      return [
      (v) => !!v || "Filim Name is required",
      (v) => /^[a-zA-Z0-9\s]+$/.test(v) || "No Special Charecters are Allowes"
    ];
  },
    noofmembersRules() {
      return [
        (v) => !!v || "No. of Members is required",
        (v) => /^[0-9]*$/.test(v) || "No. of Members must be digits",
      ];
    },
    noofvehicleRules() {
      return [
        (v) => !!v || "No. of Vehicles is required",
        (v) => /^[0-9]*$/.test(v) || "No. of Vehicles must be digits",
      ];
    },
    speciesnameRules() {
            return [
                (v) => !!v || "species Name is required",
                (v) => /^[A-Za-z\s]+$/.test(v) || "species must contain only alphabets"
            ];
            },
    noofanimalsRules() {
      return [
        (v) => !!v || "No. of Animals is required",
        (v) => /^[0-9]*$/.test(v) || "No. of Members must be digits",
      ];
    },
    preshootRules() {
      return [
        (v) => !!v || "Preshoot Order Number is required",
        (v) => /^[0-9]+$/.test(v) || "Preshoot Order Number must contain only digits",
      ];
    },
  },
  mounted() {
    this.getCircle();
    // this.checkAvailability();
  },

  methods: {
    isValidAadhaar(aadhaarNumber) {
      const aadhaarRegex = /^\d{12}$/;
      return aadhaarRegex.test(aadhaarNumber);
    },
    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },
    confirmSubmit() {
      if (this.submitting) {
        return;
      }
      this.loading = true;
      this.submitting = true;
      setTimeout(() => {
        this.loading = false;
        this.add();
        this.submitdialog = false;
        this.submitting = false;
      }, 2000);
    },
    getDivision() {
      this.appLoading = true;
      const selectedCircle = this.circle.find((c) => c._id === this.circleid);
      if (selectedCircle) {
        const path = selectedCircle.path;
        axios({
          method: "post",
          url: "/get/divsions",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            path: path,
          },
        })
          .then((response) => {
            this.division = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    // checkMobileNumber() {
    //   const digitsCount = this.applicantMobileNo.toString().length;

    //   if (digitsCount !== 10) {
    //     window.alert("Mobile number should be exactly 10 digits");
    //   }
    // },
    checkPincode() {
      const digitsCount = this.applicantPinCode.toString().length;

      if (digitsCount !== 6) {
        window.alert("Pincode should be exactly 6 digits");
      }
    },
    isValidMobileNumber(mobileNumber) {
      const mobileNumberRegex = /^[0-9]{10}$/;
      return mobileNumberRegex.test(mobileNumber);
    },
    getRange() {
      this.appLoading = true;
      const selectedDivision = this.division.find(
        (d) => d._id === this.divisionid
      );
      if (selectedDivision) {
        const path = selectedDivision.path;

        axios({
          method: "post",
          url: "/get/range",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            divisionid: this.divisionid,
            path: path,
          },
        })
          .then((response) => {
            this.range = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    goBack() {
      this.$router.push({ path: "/mybooking" });
    },
    getCircle() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/circles",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {},
      })
        .then((response) => {
          this.circle = response.data.data;

          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    handleFileChange(field) {
      this[field] = this.$refs[field].files[0];
    },
    handleFileChange2(field) {
      this[field] = this.$refs[field].files[0];
    },
    validateFile(file) {
      return file.size <= 1 * 1024 * 1024; // 1 MB in bytes
    },

    // submit2() {
    //   this.nameofOffice = null;
    //   this.memberdialog = false;
    // },
    filmShootingDetails() {
      this.leaderDetails.push({
        location: "",
        startDate: "",
        endDate: "",
        noOfMembers: "",
        noOfVehicles: "",
        filmName:"",
      });
      this.location = "";
      this.startDate = "";
      this.endDate = "";
      this.noOfMembers = "";
      this.noOfVehicles = "";
      this.filmName = "";
    },
    removeValues() {
      this.leaderDetails.pop();
    },
    removeleaderdetails(index) {
      this.leaderDetails.splice(index, 1);
    },
    addValues() {
      this.memberDetails.push({
        memberGender: "",
        memberDob: "",
        memberName: "",
      });
    },
    removeVehicle(index) {
      this.memberDetails.splice(index, 1);
    },

    validateInput() {
      if (this.idProof && !this.validateFile(this.idProof)) {
        alert("File size exceeds 1 MB. Please select a smaller file.");
        return;
      } else if (!this.circleid) {
        this.msg = "Please provide the circle.";
        this.showSnackBar = true;
        return;
      } else if (!this.divisionid) {
        this.msg = "Please provide the division.";
        this.showSnackBar = true;
        return;
      } else if (!this.rangeid) {
        this.msg = "Please provide the range.";
        this.showSnackBar = true;
        return;
      }else if (!this.animalinvolved) {
        this.msg = "Please provide the animal details.";
        this.showSnackBar = true;
        return;
      }else if (!this.natureOfApplication) {
        this.msg = "Please provide the Nature Of Application.";
        this.showSnackBar = true;
        return;
      } 
      // else if (!this.idcardtypes) {
      //   this.msg = "Please provide the ID card type.";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.idcardNo) {
      //   this.msg = "Please provide the ID card Nummber.";
      //   this.showSnackBar = true;
      //   return;
      // } 
      // // else if (!this.location) {
      // //   this.msg = "Please provide the Location.";
      // //   this.showSnackBar = true;
      // //   return;
      // // }
      // //  else if (!this.filmName) {
      // //   this.msg = "Please cccccccccccc provide the Film Name.";
      // //   this.showSnackBar = true;
      // //   return;
      // // }
      //  else if (!this.fromDate) {
      //   this.msg = "Please provide the From Date.";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.toDate) {
      //   this.msg = "Please provide the To Date.";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.noOfMembers) {
      //   this.msg = "Please provide the No. of Members.";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.noOfVehicles) {
      //   this.msg = "Please provide the No. of Vehicle";
      //   this.showSnackBar = true;
      //   return;
      // // } else if (!this.filmName) {
      // //   this.msg = "Please provide the Film Name.";
      // //   this.showSnackBar = true;
      // //   return;
      // // } else if (!this.filmName) {
      // //   this.msg = "Please provide the Film Name.";
      // //   this.showSnackBar = true;
      // //   return;
      // } 
      // // else if (this.idType === 'Aadhaar Card' && (this.idcardNo.length !== 12 || !this.isValidAadhaar(this.idcardNo))) {
      // //   this.msg = "Please provide a valid Aadhaar Card number.";
      // //   this.showSnackBar = true;
      // //   return;
      // // } 
      else if (!this.isValidEmail(this.applicantEmail)) {
        this.msg = "Please provide a valid email address.";
        this.showSnackBar = true;
        return;
      } else if (this.idType === "Driving License" && (!this.idcardNo || this.idcardNo.length !== 16 || !/^[A-Z0-9-]{16}$/.test(this.idcardNo))) {
    this.msg = "Please provide a valid 16-character Driving License number.";
    this.showSnackBar = true;
    return;
  } else if (this.idType === "Election Id Card" && (!this.idcardNo || !/^[A-Z]{3}[0-9]{7}$/.test(this.idcardNo))) {
    this.msg = "Please provide a valid Election ID number (3 letters followed by 7 digits).";
    this.showSnackBar = true;
    return;
  } else if (this.idType === "Aadhaar Card" && (!this.idcardNo || this.idcardNo.length !== 12 || !/^[0-9]{12}$/.test(this.idcardNo))) {
    this.msg = "Please provide a valid 12-digit Aadhaar Card number.";
    this.showSnackBar = true;
    return;
   } else if (!this.isValidMobileNumber(this.applicantMobileNo)) {
        this.msg = "Please provide a valid mobile number.";
        this.showSnackBar = true;
        return;
      } else if (!this.isValidPincode(this.applicantPinCode)) {
        this.msg = "Please provide a valid 6-digit pincode.";
        this.showSnackBar = true;
        return;
      } else {
        this.submitdialog = true;
      }
    },
    isValidPincode(pincode) {
      const pincodePattern = /^\d{6}$/;
      return pincodePattern.test(pincode);
    },

    add() {
      const formData = new FormData();
      formData.append("idProof", this.idProof);
      formData.append("Preshootfile", this.Preshootfile);
      formData.append(
        "filmShootingDetails",
        JSON.stringify(this.leaderDetails)
      );
      formData.append("natureOfApplication", this.natureOfApplication);
      formData.append("applicantAddress", this.applicantAddress);
      formData.append("idType", this.idType);
      formData.append("applicantMobileNo", this.applicantMobileNo);
      formData.append("applicantName", this.applicantName);
      formData.append("applicantPinCode", this.applicantPinCode);
      formData.append("divisionid", this.divisionid);
      formData.append("rangeid", this.rangeid);
      formData.append("idcardNo", this.idcardNo);
      formData.append("applicantEmail", this.applicantEmail);
      formData.append("applicantDistrict", this.applicantDistrict);
      formData.append("animalinvolved", this.animalinvolved);
      formData.append("species", this.species);
      formData.append("noOfAnimal", this.noOfAnimal);
      formData.append("preshootNumber", this.preshootNumber);
      formData.append("validityfromDate", this.validityfromDate);
      formData.append("validitytoDate", this.validitytoDate);
      formData.append("isAgree", this.isAgree);
      axios({
        method: "POST",
        url: "/apply/shooting",
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            alert(response.data.msg);
            this.msg = response.data.msg;
            this.appid = response.data.data._id;
            this.submitdialog = false;
            window.location.reload();
            // this.successdialog = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    submit() {
      axios({
        method: "POST",
        url: "/final/submit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.appid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitdialog = false;
            // this.$router.push({ path: "/mybooking" });
            // window.location.reload();
            // this.$router.push({ path: "/mybooking" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    // checkAvailability() {
    //   axios({
    //     method: "POST",
    //     url: "/check/availability",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       date: this.startDate,
    //       campid: this.$route.query.id,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.list = response.data.natureCampDetails;
    //         this.availableSlots2 = response.data.availableSlots;
    //         this.message = response.data.data;
    //         // this.msg = response.data.msg;
    //         // this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.error(err);
    //     });
    // },
    // checkAvailability2() {
    //   axios({
    //     method: "POST",
    //     url: "/check/availability",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       nameofOffice: this.nameofOffice,
    //       availableSlots: this.availableSlots2,
    //       campid: this.$route.query.id,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         // this.msg = response.data.msg;
    //         // this.showSnackBar = true;

    //         this.list = response.data.natureCampDetails;
    //       } else {
    //         this.memberdialog = true;

    //         // this.msg = response.data.msg;
    //         // this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.error(err);
    //     });
    // },
  },
};
</script>
<style>
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>