<template>
    <div>
      <v-layout wrap justify-center>
        <v-snackbar v-model="showSnackBar" color="black">
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: white">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: white">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
          spinner="spinner" />
        <v-flex>
          <v-layout wrap justify-center>
            <v-flex xs11 text-center pt-7 pb-2>
              <span style="font-size: 23px;font-weight: bold;">Application form for online reservation of Nature camp</span>
            </v-flex>
            <v-flex pa-4 xs12 text-center>
              <v-card>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-center>
                    <v-card>
                      <v-layout wrap justify-center>
                        <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                          <span style="font-size: 20px;color: white;">Nature Camp Details</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout wrap>
                            <v-flex pl-3 xs4 text-left align-self-center>
                              <v-autocomplete outlined dense color="black" :items="districts" v-model="list.district"
                                item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                                class="custom-autocomplete  mainfont">
                                <template v-slot:label>
                                  <span class="custom-label-text" style="color: black; font-size: 14px">District
                                    <span style="color:red;font-size:20px">*</span>
</span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black">mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-flex>
  
                            <v-flex xs4 pl-3 align-self-center pt-6>
                              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field clearable dense outlined readonly v-model="cdate" v-bind="attrs"
                                    background-color="white" v-on="on" @click:clear="fromDate = null" style="color: black">
                                    <template v-slot:append>
                                      <v-icon v-bind="attrs" v-on="on" color="black">mdi-chevron-down</v-icon>
                                    </template>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">From Date</span>
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker v-model="cdate" color="#13736f" @change="menu1 = false"></v-date-picker>
                              </v-menu>
                            </v-flex>
                            <v-flex xs4>
                              <v-layout wrap justify-center>
                                <v-flex xs6 align-self-center>
                                  <span>Duration of Camp <span style="color:red;font-size:20px">*</span>
</span>
                                </v-flex>
                                <v-flex xs4>
                                  <v-radio-group v-model="list.campDuration">
                                    <v-radio label="1 day" value="1"></v-radio>
                                    <v-radio label="2 days" value="2"></v-radio>
                                    <v-radio label="3 days" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs6>
                          Selected camp at Pamba on {{ startDate }} can accommodate people as follows
                        </v-flex>
                        <v-flex xs7 pt-3 pb-9>

<v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
  <v-layout wrap pt-3 pb-3 justify-center>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs3 pt-2 text-center>
          <span class="subheading">Staff/Group Leader</span>
        </v-flex>
        <v-flex pt-2 xs3 text-center>
          <span class="subheading">Male Members</span>
        </v-flex>
        <v-flex pt-2 xs3 text-center>
          <span class="subheading">Female Members</span>
        </v-flex>
        <v-flex pt-2 xs3 text-center>
          <span class="subheading">Transgender Members</span>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 pt-3 pb-3>
      <v-divider></v-divider>
    </v-flex>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex v-if="list" pb-2 xs3 text-center>
          <span v-if="list.campid">
            <span v-if="list.campid.totalMembers">{{ list.campid.totalMembers }}</span>

          </span>

        </v-flex>
        <v-flex v-if="list" pb-2 xs3 text-center>


          <span v-if="list.campid">
            <span v-if="list.campid.membersLimit">
              <span v-if="list.campid.membersLimit.male">
                {{ list.campid.membersLimit.male }}

              </span>

            </span>

          </span>


        </v-flex>
        <v-flex v-if="list" pb-2 xs3 text-center>


          <span v-if="list.campid">
            <span v-if="list.campid.membersLimit">
              <span v-if="list.campid.membersLimit.male">
                {{ list.campid.membersLimit.male }}

              </span>

            </span>

          </span>


        </v-flex>



        <v-flex v-if="list" pb-2 xs3 text-center>


          <span v-if="list.campid">
            <span v-if="list.campid.membersLimit">
              <span v-if="list.campid.membersLimit.transgender">
                {{ list.campid.membersLimit.transgender }}

              </span>

            </span>

          </span>


        </v-flex>

      </v-layout>
    </v-flex>
  </v-layout>
</v-card>
</v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
  
            </v-flex>
  
  
            <v-flex pa-4 xs12 text-center>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12 text-center>
                    <v-card>
                      <v-layout wrap justify-center>
                        <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                          <span style="font-size: 20px;color: white;">
                            Applicant Details
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout wrap>
  
                            <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                              <v-text-field type="number" dense color="#283e51" outlined background-color="white"
                                v-model="list.memberCount" hide-details>
                                <template v-slot:label>
                                  <span class="custom-label-text" style="color: black; font-size: 14px">
                                    Member Count<span style="color:red;font-size:20px">*</span>

                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                              <v-autocomplete outlined dense color="black" :items="applicantcategorys"
                                v-model="list.applicantCategory" item-text="name" item-value="name" item-color="#FF1313"
                                hide-details clearable class="custom-autocomplete mainfont">
                                <template v-slot:label>
                                  <span class="custom-label-text" style="color: black; font-size: 14px">
                                    applicantCategory <span style="color:red;font-size:20px">*</span>

                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black">mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-flex>
  
  
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
  
            </v-flex>
  
            <v-flex pa-4 xs12 text-center>
              <v-card>
                <template>
                  <div>
                    <v-layout wrap>
                      <v-flex xs12 text-center>
                        <v-card>
                          <v-layout wrap justify-center>
                            <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                              <span style="font-size: 20px;color: white;">
                                Institution Details
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap>
                                <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                                  <v-text-field type="text" dense color="#283e51" outlined background-color="white"
                                    v-model="list.instName" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 13px">
                                        Name of the Institution/Organisation <span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                                  <v-text-field dense color="#283e51" outlined background-color="white" v-model="list.instCode"
                                    hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Institution/Organisation code <span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                                  <v-autocomplete outlined dense color="black" :items="institutionTypes" v-model="list.instType"
                                    item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                                    class="custom-autocomplete  mainfont">
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Type of Institution <span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                    <template v-slot:append>
                                      <v-icon color="black">mdi-chevron-down</v-icon>
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
  
                                <v-flex pl-3 xs3 pt-9 pr-3 pb-6 text-left align-self-center>
                                  <v-text-field color="#283e51" outlined background-color="white" v-model="list.instAddress"
                                    hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 13px">
                                        Address of the Institution/Organisation <span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
  
                                <!-- Add the new fields here -->
                                <v-flex pl-3 xs3 pt-3 pb-6 text-left align-self-center>
                                  <v-text-field type="number" dense color="#283e51" outlined background-color="white"
                                    v-model="list.instTeleNo" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Institution Phone Number <span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex pl-3 xs3 pt-3 pb-6 text-left align-self-center>
                                  <v-text-field type="number" :rules="PhoneRules" dense color="#283e51" outlined
                                    background-color="white" v-model="list.instMobileNo" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Institution Mobile Number <span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex pl-3 xs3 pt-3 pb-6 text-left align-self-center>
                                  <v-text-field :rules="emailRules" dense color="#283e51" outlined background-color="white"
                                    v-model="list.instEmail" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Institution Email<span style="color:red;font-size:20px">*</span>

                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
  
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </div>
                </template>
  
  
              </v-card>
  
            </v-flex>
  
  
            <v-flex pa-4 xs12 text-center>
              <v-card>
  
                <template>
                  <div>
                    <v-layout wrap>
                      <v-flex xs12 text-center>
                        <v-card>
                          <v-layout wrap justify-center>
                            <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                              <span style="font-size: 20px;color: white;">Details of Head of Institution</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap>
                                <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                                  <v-text-field dense color="#283e51" outlined background-color="white"
                                    v-model="list.instHeadName" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">Name of Head of
                                        Institution/Organisation <span style="color:red;font-size:20px">*</span>
</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                                  <v-text-field dense color="#283e51" outlined background-color="white"
                                    v-model="list.headDesignation" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">Designation in
                                        Institute /Organisation <span style="color:red;font-size:20px">*</span>
</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
  
                                <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                                  <v-autocomplete outlined dense color="black" :items="idcardtypes" v-model="list.idCardType"
                                    item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                                    class="custom-autocomplete mainfont">
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">id card
                                        type<span style="color:red;font-size:20px">*</span>
</span>
                                    </template>
                                    <template v-slot:append>
                                      <v-icon color="black">mdi-chevron-down</v-icon>
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
  
                                <v-flex pl-3 xs3 pt-6 pb-6 pr-3 text-left align-self-center>
                                  <v-text-field type="number" dense color="#283e51" outlined background-color="white"
                                    v-model="list.idNo" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">Id Card Number
                                        <span style="color:red;font-size:20px">*</span>
</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </div>
                </template>
  
              </v-card>
  
            </v-flex>
  
            <v-flex pa-4 xs12 text-center>
              <v-card>
                <template>
                  <div>
                    <v-layout wrap>
                      <v-flex pl-4 pt-2 pb-2 text-left style="background-color: black;">
                        <v-layout wrap justify-start>
                          <v-flex xs5 pt-2 align-self-center>
                            <span style="font-size: 20px;color: white;">
                          Add    Group Leader/Teaching Staff Details
                            </span>&nbsp;
                            <v-btn small color="#68D389" @click="addLeaderDetails()">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start style="background-color: white;">
                      <v-card flat>
                        <v-layout wrap>
                          <v-flex pt-1 v-for="(item, i) in leaderdetails" :key="i">
                            <v-layout wrap pt-2>
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field v-model="item.leaderName" dense outlined label="Name"
                                  hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Name</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field v-model="item.leaderAddress1" dense outlined label="Address1"
                                  hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Address1</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
  
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field v-model="item.leaderAddress2" dense outlined label="Address2"
                                  hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Address2</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
  
  
  
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field color="black" v-model="item.telephone" type="number" dense outlined label="Telephone"
                                  hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Telephone</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
  
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                <v-text-field :rules="PhoneRules" v-model="item.leaderPhone" type="number" dense outlined
                                  label="Phone" hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Phone<span style="color:red;font-size:20px">*</span>
</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
  
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field color="black" :rules="emailRules" v-model="item.leaderEmail" dense outlined label="Email"
                                  hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Email<span style="color:red;font-size:20px">*</span>
</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
  
  
  
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                <v-autocomplete outlined dense color="black" :items="gender" v-model="item.leaderGender"
                                  item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                                  class="custom-autocomplete  mainfont">
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Gender<span style="color:red;font-size:20px">*</span>
</span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black">mdi-chevron-down</v-icon>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <v-flex lg2>
                                <center>
                                  <v-btn dark style="background-color: #68d389" dense @click="removeleaderdetails(i)">
                                  Remove
                                </v-btn>
                                </center>
                                
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
  
                    </v-layout>
                  </div>
                </template>
  
  
              </v-card>
  
            </v-flex>
  
  
  
  
  
  
            <v-flex pa-4 xs12 text-center>
              <v-card>
  
                <template>
                  <div>
                    <v-layout wrap>
                      <v-flex pl-4 pt-2 pb-2 text-left style="background-color: black;">
                        <v-layout wrap justify-start>
                          <v-flex xs4 pt-2 align-self-center>
                            <span style="font-size: 20px;color: white;">
                           Add   Member Details
  
                            </span>&nbsp;
                            <v-btn small color="#68D389" @click="addValues()">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start style="background-color: white;">
                      <v-card flat>
                        <v-layout wrap>
                          <v-flex pt-1 v-for="(item, i) in memberdetails" :key="i">
                            <v-layout wrap pt-2>
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field v-model="item.membername" dense outlined label="membername"
                                  hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Member Name<span style="color:red;font-size:20px">*</span>
</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
  
                                <v-text-field v-model="item.dob" dense outlined label="dob" hide-details>
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      DOB<span style="color:red;font-size:20px">*</span>
</span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                <v-autocomplete outlined dense color="black" :items="gender" v-model="item.genders"
                                  item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                                  class="custom-autocomplete  mainfont">
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Gender<span style="color:red;font-size:20px">*</span>
</span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black">mdi-chevron-down</v-icon>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <v-flex lg2>
                                <v-btn dark style="background-color: #68d389" dense @click="removeVehicle(i)">
                                  Remove
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-layout>
                  </div>
                </template>
              </v-card>
            </v-flex>
            <v-flex pa-4 xs12 text-center>
            <v-card>
          <v-layout wrap>
            <v-flex xs12 text-center>
              <v-card>
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <template>
                      <div>
                        <v-layout wrap justify-center>
                          <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                            <span style="font-size: 20px;color: white;">
                              Scanned Documents
                            </span>
                          </v-flex>
                          <v-flex xs12 text-center pa-4>
                            <span style="font-size: 20px;color: black;">
                              (Documents must be PDF format. File size cannot exceed 100 KB)
                            </span>
                          </v-flex>
                          <v-flex xs11 pb-6>
                              <v-layout wrap justify-center>
                                <v-flex xs6 text-left>
                                  <label for="idProof">
                                    <span>ID proof of Head of institution / Organisation <span style="color:red;font-size:23px">*</span>

</span>
                                  </label>
                                </v-flex>
                                <v-flex xs2>

 <a :href="leaderIdProof" download="leaderIdProof.pdf">
      <v-btn> View PDF</v-btn>
    </a>
                                </v-flex>
                                <v-flex xs4>
                                  <input type="file" id="idProof" ref="idProof" accept=".pdf" @change="handleFileChange('idProof')" />
                                </v-flex>

                                <v-flex xs6 pt-4 text-left>
                                  <label for="reqLetter" text-left>
                                    <span>Request Letter from Head of Institution/ Organisation <span style="color:red;font-size:23px">*</span>

</span>
                                  </label>
                                </v-flex>
                                <v-flex xs6 pt-4>
                                  <input type="file" id="reqLetter" ref="reqLetter" accept=".pdf" @change="handleFileChange('reqLetter')" />
                                </v-flex>

                                <v-flex xs6 pt-4 text-left>
                                  <label for="participantfile" text-left>
                                    <span>signed by Head of Institution/ Organisation <span style="color:red;font-size:23px">*</span>

</span>
                                  </label>
                                </v-flex>
                                <v-flex xs6 pt-4>
                                  <input type="file" id="participantfile" ref="participantfile" accept=".pdf" @change="handleFileChange('participantfile')" />
                                </v-flex>

                                <v-flex xs6 pt-4 text-left>
                                  <label for="leaderIdProof" text-left>
                                    <span>ID Proof of one of the Group Leader/Teaching staff <span style="color:red;font-size:23px">*</span>

</span>
                                  </label>
                                </v-flex>
                                <v-flex xs6 pt-4>
                                  <input type="file" id="leaderIdProof" ref="leaderIdProof" accept=".pdf" @change="handleFileChange('leaderIdProof')" />
                                </v-flex>
                              </v-layout>

                          </v-flex>
                        </v-layout>
                      </div>
                    </template>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
          </v-flex>
  
            <v-flex pa-4 xs12 text-center>
              <v-card>
                <v-layout wrap justify-center>
                  <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                    <span style="font-size: 20px;color: white;">
                      Undertaking
                    </span>
                  </v-flex>
                  <v-flex pt-3 xs1>
                    <v-checkbox v-model="isChecked"></v-checkbox>
                  </v-flex>
  
                  <v-flex xs10 pb-5 text-left>
                    <v-layout wrap pt-4>
                      <v-flex xs12 pt-4 v-for="(content, index) in agreementContent" :key="index">
                        <span>{{ content }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs9 pb-10>
                    <v-layout wrap justify-start>
                      <v-flex xs4>
                        <v-text-field type="text" dense color="#283e51" outlined background-color="white"
                          v-model="list.applicantname" hide-details>
                          <template v-slot:label>
                            <span class="custom-label-text" style="color: black; font-size: 13px">
                              Name of the applicant <span style="color:red;font-size:20px">*</span>
</span>
                          </template>
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-10>
          <v-layout wrap justify-end>
            <v-flex xs2 pr-4 >
        <v-btn  color="black" block @click="add()">
          <span style="color:white">
            SAVE CHANGES
          </span>
          </v-btn>
  
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
import moment from "moment";

  export default {
    components: {
    },
    data() {
      const today = new Date().toISOString().substr(0, 10);
      return {
        idProof:{},
reqLetter:{},

participantfile:{},
leaderIdProof:{},
        cdate:{},
        gender: ["Male", "Female", "Transgender"],
        memberdetails: [],
        leaderName: null,
        genders: ["Male", "Female", "Transgender"],
        leaderAddress1: null,
        leaderAddress2: null,
        telephone: null,
        idCardType: null,
        leaderPhone: null,
        leaderGender: null,
        leaderEmail: null,
        district: null,
        instHeadName: null,
        headDesignation: null,
        idCardTypeidCardType: null,
        idNo: null,
        idcardtypes: [
          "Election Id Card",
          "Driving License",
          "Aadhaar Card",
        ],
        instName: null,
        instCode: null,
        instType: null,
        instAddress: null,
        instTeleNo: null,
        instMobileNo: null,
        instEmail: null,
        institutionTypes: [
          "Government",
          "Aided",
          "Private",
          "Others"
        ],
        startDate: today,
        showSnackBar: false,
        isChecked: false,
        appLoading: false,
        msg: "",
        expandSection: false,
        name: null,
        barlist: [],
        agreementContent: [
          "I, hereby undertake on behalf of the Institution/Organization/Team that myself and all the Team members taking part in the Nature Camp shall abide by the conditions as per the existing laws pertaining to Forests & Wildlife, orders of the Forest Officers/competent authorities during the Nature Camp and maintain discipline throughout the course of the Nature Camp.",
          "I, hereby declare that the entries made by me, for the members of the team, in the Application Form are complete and true to the best of my knowledge, belief, and information.",
          "I, hereby undertake to present the original documents/ID proof (of Group leader) for verification immediately upon demand by the competent authorities, as and when required.",
          "I, hereby declare that I and or my team/group members shall be solely responsible for our involvement in any kind of unlawful activities whether inside or outside the premises at the selected location of the Nature Camp, and shall be liable for punishment, if any, as per the law of the land.",
          "I further declare that we are aware that the permission given to me and or my team may be canceled, at any stage, if I and or my team members are found ineligible and/or the information provided by me are found to be incorrect.",
          "I declare that my team members and I will strictly adhere to the code of conduct, as issued by the Competent Authority for the Nature Camp Programme at the selected location.",
          "I declare that I am aware of the fact that, even if a Nature Camp is allotted at selected location to me and or my team, the Camp could be canceled at any stage, by the Divisional Forest Officer/Wildlife Warden or any Competent Authority and I have no objection and I and or my team members and or my Head of Institution/Organisation will not raise any dispute against this."
        ],
  
        list: {},
        selectedDistrict: null,
        districts: [
          "Thiruvananthapuram",
          "Kollam",
          "Pathanamthitta",
          "Alappuzha",
          "Kottayam",
          "Idukki",
          "Ernakulam",
          "Thrissur",
          "Palakkad",
          "Malappuram",
          "Kozhikode",
          "Wayanad",
          "Kannur",
          "Kasaragod"
        ],
        campDuration: '',
        menu1: false,
        male: null,
        memberCount: null,
        leaderdetails: [],
  
        applicantCategory: "",
        applicantname: null,
        applicantcategorys: [
          "Students",
          "Press Reporters",
          "LSG Members",
          "NGO youth/charitable organization",
        ],
  
      };
    },
    watch: {
      // startDate() {
      //   this.checkAvailability();
      // },
  
    },
    computed: {
      PhoneRules() {
        return [
          (v) => !!v || "Phone number is required",
          (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
        ];
      },
      emailRules() {
        return [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
      },
  
  
    },
    mounted() {
      this.getList();
    // this.checkAvailability();

    },
  
  
    methods: {
      
    // checkAvailability() {
    //   axios({
    //     method: "POST",
    //     url: "/check/availability",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       date: this.startDate,
    //       campid: this.$route.query.id,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.list = response.data.natureCampDetails;
    //         this.emitCampDetailsUpdated();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.error(err);
    //     });
    // },
      addvehi() {
        console.log("sasasa", this.leaderdetails)
  
      },
      addLeaderDetails() {
        this.leaderdetails.push({ leaderName: '', leaderGender: '', leaderAddress1: '', leaderAddress2: '', telephone: '', leaderPhone: '', leaderEmail: '' },);
        this.leaderGender = '';
        this.leaderName = '';
        this.leaderAddress1 = '';
        this.leaderAddress2 = '';
        this.telephone = '';
        this.leaderPhone = '';
        this.leaderEmail = '';
  
  
  
      },
      removeValues() {
        this.leaderdetails.pop();
      },
      removeleaderdetails(index) {
        this.leaderdetails.splice(index, 1);
      },
  
  
      addValues() {
        this.memberdetails.push({ genders: '', dob: '', membername: '' });
      },
      removeVehicle(index) {
        this.memberdetails.splice(index, 1);
      },
      add() {
        axios({
          method: "POST",
          url: "/apply/camp",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            leaderdetails: this.leaderdetails,
            memberdetails: this.memberdetails,
            leaderName: this.leaderName,
            leaderAddress1: this.leaderAddress1,
            leaderAddress2: this.leaderAddress2,
            telephone: this.telephone,
            leaderPhone: this.leaderPhone,
            leaderGender: this.leaderGender,
            leaderEmail: this.leaderEmail,
            instHeadName: this.instHeadName,
            headDesignation: this.headDesignation,
            idCardType: this.idCardType,
            idNo: this.idNo,
            campid: this.$route.query.id,
            instName: this.instName,
            instCode: this.instCode,
            instType: this.instType,
            instAddress: this.instAddress,
            instTeleNo: this.instTeleNo,
            instMobileNo: this.instMobileNo,
            instEmail: this.instEmail,
            startDate: this.startDate,
            isChecked: this.isChecked,
            expandSection: this.expandSection,
            name: this.name,
            selectedDistrict: this.selectedDistrict,
            district: this.district,
            campDuration: this.campDuration,
            menu1: this.menu1,
            male: this.male,
            memberCount: this.memberCount,
            applicantCategory: this.applicantCategory,
            applicantname: this.applicantname,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.emitCampDetailsUpdated();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.error(err);
          });
      },
      getList() {
        axios({
          method: "POST",
          url: "/single/application",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            appid: this.$route.query.id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.list = response.data.data;

              this.idProof = this.list.idProof;
              this.reqLetter = this.list.reqLetter;
this.participantfile = this.list.participantfile;

this.leaderIdProof = this.list.leaderIdProof;
              this.cdate = this.list.startDate;
              console.log("asaas",this.startDate)
              this.cdate = moment(this.cdate).format("YYYY-MM-DD");
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.error(err);
          });
      },
    },
  };
  </script>
    <!-- <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs2>
            <v-btn color="primary" @click="goBack">
              <span style="text-transform: none; font-size: 15px; color: white;">
                GO BACK
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> -->
goBack() {
  // Use the router to navigate back to the previous page
  this.$router.go(-1);
},